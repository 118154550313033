import { Card, CardContent, Typography, Grid, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import logo from './assets/diligent.png';
import Footer from './components/Footer';
function AboutUs() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
  };

  const desktopStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  };

  return (
    <div style={{backgroundColor: '#F5F5F5', minHeight: '150vh'}}>

    <header style={isMobile ? { ...desktopStyle, ...mobileStyle } : desktopStyle}>
      <img src={logo} alt="Company Logo" height="40" style={{ marginRight: '10px' }} />

      <div>
        <a href="/about-us" style={{ marginRight: '20px',  textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>About Us</a>
        <a href="/contact-us" style={{ marginRight: '20px', textDecoration: 'none', fontWeight: '500', color:'#635f5f' }}>Contact Us</a>
        <Button variant="contained" color="primary" href="/sign-in" style={{ marginRight: '20px' }}>Sign In</Button>
      </div>
    </header>
    <Container style={{ paddingTop: '100px' }}>
      <Typography variant="h3" gutterBottom>About Us</Typography>
      <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" component="div">Meet the Team Behind Diligent Care</Typography>
        <Typography paragraph>
          At Diligent Care, our clinician-driven team is dedicated to transforming diabetes care through innovative technology and a deep understanding of chronic conditions. Our mission is to strengthen the patient-provider connection by offering personalized, precise diabetes management and enhancing the post-encounter experience for better health outcomes.
        </Typography>
      </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">Shelley Kemmerer, PA-C, MCHS, Co-Founder, CEO</Typography>
              <Typography paragraph>
                A serial entrepreneur and board-certified Physician Assistant, Shelley has experience in managing complex chronic conditions. With her expertise in burnout prevention and patient care, Shelley leads Diligent Care with a vision to simplify healthcare interactions and improve patient outcomes. Education: Medex Northwest, UW School of Medicine.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">Saroj Kumar Rout, Co-Founder, CTO</Typography>
              <Typography paragraph>
              Saroj is a seasoned founder with a successful track record in the tech industry. With extensive experience at top tech companies like Apple, Intuit, and Adobe, Saroj has contributed to over $100M ARR. His technical expertise drives the innovative solutions Diligent Care offers, ensuring they are both cutting-edge and user-friendly.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">Dr. Florence Hsu, MD, Chief Clinical Advisor</Typography>
              <Typography paragraph>
              Dr. Hsu brings over 25 years of clinical experience, specializing in rheumatology and complex chronic conditions. Recognized as a 'Top Doctor' in Seattle, Dr. Hsu's clinical insights guide the development of Diligent Care's patient-centric features. Education: Stanford University, Case Western Reserve University.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
        <Typography paragraph>
        Together, our team’s unique blend of clinical expertise and technological innovation positions us to create solutions that truly meet the needs of both patients and providers. With our deep understanding of complex chronic conditions and a proven track record in tech, we are equipped to revolutionize diabetes care. Diligent Care is dedicated to simplifying healthcare and enhancing the patient-provider relationship.
        </Typography>
      </Grid>
      </Grid>
    </Container>
      <Footer />
    </div>
  );
}

export default AboutUs;